<template>
    <div style="height: 100%;">
        <!-- Início filtro -->
        <v-dialog v-model="modal.filtro" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar color="#018656" dark>
                <v-app-bar-nav-icon @click="modal.filtro = false">
                    <v-icon>fas fa-times</v-icon>
                </v-app-bar-nav-icon>
                <v-icon class="ml-2">fas fa-dolly</v-icon>
                <v-toolbar-title class="pl-4">Filtrar Modais</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-app-bar-nav-icon @click="limparFiltros">
                    <v-icon>fas fa-broom</v-icon>
                </v-app-bar-nav-icon>
                <v-app-bar-nav-icon @click="carregarTransportadoras(), modal.filtro = false">
                    <v-icon>fas fa-search</v-icon>
                </v-app-bar-nav-icon>
            </v-toolbar>
            <v-card-text>
            <v-col class="pt-6">
                <v-form>
                <v-row>
                    <v-col cols="12" md="3">
                    <v-text-field dense outlined prepend-icon="fas fa-fingerprint" label="Código" type="number"
                        autocomplete="off" v-model="filtros.codigo" clearable></v-text-field>
                    </v-col>
                    <v-col cols="12" md="9">
                    <v-text-field dense outlined prepend-icon="fas fa-dolly" label="Nome" type="text"
                        autocomplete="off" v-model="filtros.nome" clearable></v-text-field>
                    </v-col>
                </v-row>
                </v-form>
            </v-col>
            </v-card-text>
        </v-card>
        </v-dialog>
        <!-- Fim filtro -->

        <!-- Inicio Edit Modal -->
        <v-dialog v-model="modal.edit" width="900">
            <v-card class="overflow-hidden">
                <v-card-item class="d-flex">
                    <v-spacer></v-spacer>
                    <v-card-title class="text-h5">Editar Modal "{{ modalNome }}"</v-card-title><v-spacer></v-spacer>
                    <v-btn icon @click="modal.edit = false">
                        <v-icon color="error">fas fa-times</v-icon>
                    </v-btn>
                </v-card-item>

                <v-form>
                    <v-row class="justify-center pa-2">
                        <v-col cols="2" class="pr-0">
                            <v-text-field
                            outlined
                            :rules="rulesChar"
                            type="number"
                            v-model="editedItem.codigo"
                            label="Código"
                            disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field
                            outlined
                            clearable
                            type="text"
                            :rules="rulesChar"
                            v-model="editedItem.nome"
                            label="Nome"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="justify-end ma-4">
                        <v-btn @click="modal.edit = false" class="mr-2" color="#F44336" style="color: white;">
                            Cancelar
                        </v-btn>
                        <v-btn @click="editModal()" color="#018656" style="color: white;">
                            Salvar
                        </v-btn>                        
                    </v-row>
                </v-form>
            </v-card>
        </v-dialog>
        <!-- Fim Edit Modal -->

        <!-- Inicio Principal -->
        <v-card>
            <v-toolbar flat>
                <v-icon size="200"> fas fa-dolly </v-icon>
                <v-toolbar-title class="pl-4"> Modais </v-toolbar-title>
                <v-spacer></v-spacer>                
                <v-app-bar-nav-icon @click="modal.filtro = true">
                    <v-icon>fas fa-search</v-icon>
                </v-app-bar-nav-icon>
                <v-app-bar-nav-icon @click="fechar">
                    <v-icon>fas fa-times</v-icon>
                </v-app-bar-nav-icon>
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text>
                <v-row>
                <v-col class="px-6 py-6">
                    <v-data-table
                    dense
                    :headers="grid.modais.cabecalho"
                    :items="grid.modais.items"
                    :items-per-page="grid.modais.paginacao"
                    hide-default-footer
                    class="elevation-1 pt-4"
                    :loading="grid.modais.carregando"
                    >
                    <template v-slot:item.actions="{ item }">
                        <div>
                            <v-icon style="margin-left: 8px;" color="primary" @click="editItem(item)" size="small" class="me-2">
                                fas fa-pen
                            </v-icon>
                        </div>
                    </template>
                    </v-data-table>
                </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <!-- Fim Principal -->
    </div>
</template>

<script>
export default {
    name: 'Modais',
    data: () => ({
        grid: {
            modais: {
                cabecalho: [
                    { text: "Código", value: "codigo", width: '20%'},
                    { text: "Nome", value: "nome", width: '70%'},
                    { text: "Editar", value: "actions", sortable: false},
                ],
                items: [
                    {codigo: "1", nome: "Vidro"},
                    {codigo: "2", nome: "Líquido"},
                    {codigo: "3", nome: "Químico"},
                    {codigo: "4", nome: "Volumoso"},
                    {codigo: "5", nome: "Pesado"},
                ],
                carregando: false,
                paginaAtual: 1,
                totalRegistros: 0,
                porPagina: 10,
            },
        },
        modal: {
            filtro: false,            
            edit: false,
        },
        filtros: {
            codigo: null,
            nome: null,
        },
        editedItem: {
            codigo: null,
            nome: '',
        },        
        rulesChar: [
            value => {
                if(value) return true

                return 'Este campo é obrigatório.'
            }
        ],
        modalNome: null,
    }),
    methods: {
        async editItem(item){
            this.modal.edit = true,
            this.modalNome = item.nome;
            this.editedItem.codigo = item.codigo;
            this.editedItem.nome = item.nome;
        },
        editModal(){
            for(var item in this.grid.modais.items){
                if(this.grid.modais.items[item].nome == this.modalNome){
                    this.grid.modais.items[item].nome = this.editedItem.nome;
                }
            }
            this.modal.edit = false;
        },
        limparFiltros(){
            this.filtros.codigo = '';
            this.filtros.nome = '';
        },
        fechar() {
          this.$router.push("/");
        },
    }
}
</script>
<style></style>